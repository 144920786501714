
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
export default defineComponent({
  name: "Chat",
  setup() {
    const my = ref(null);
    const list = ref([]);
    const message = ref("");
    const route = useRoute();
    const friendId = ref('');
    const id = route.query.id;
    function getData() {
      axios
        .get("/M/Personal/Chat/" + id, { params: { validate: true } })
        .then((res) => {
          if (res.data.success) {
            my.value = res.data.obj.my;
            list.value = res.data.obj.list;
            friendId.value = res.data.obj.friendId;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
    }
    getData();
    function onSbumit() {
      axios
        .post(
          "/M/Server/doLetters",
          qs.stringify({
            uid: (my.value as any).id,
            reciveid: friendId.value,
            contents: message.value,
          })
        )
        .then((res) => {
          if (res.data.success) {
              message.value = ''
            getData();
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
    }
    return {
      my,
      list,
      onSbumit,
      message,
    };
  },
});
